import React from 'react';
// import Navbar from './Navbar';
import MuiNavBar from './MuiNavBar';
import './Navbar.css'
function NavBar(props) {
  return (
    <MuiNavBar {...props}/>
  );
}

export default NavBar;
