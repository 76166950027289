import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { AppDownloadImage, AppLogoImage } from "../ImagesComponent";
import { json, Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import AndroidIcon from '@mui/icons-material/Store';

const pages = [
  { label: "Search", link: "/search" },
  { label: "Offers", link: "/offers" },  
  { label: "Store Manage", link: "/store-owners-corner" },
  // { label: "Interview Prep", link: "/interviewqa" },
];

function MuiNavBar() {
  const isAuthenticated = false
  const dispatch = useDispatch()
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  // const [currComponent, setCurrentComponent] = React.useState('')
  const location = useLocation()
  const currComponent = getComponentNameByURL(location.pathname)
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const updateSelectedComponent = () => {
    // setTimeout(()=>{
    //   let currComp = getComponentNameByURL()
    //   setCurrentComponent(currComp)
    // }, 500)
  }

  const logoutFunc = () => {

  }
  const isMobileScreen = window.isDeviceMobile;

  const mobileNavBar = (
    <>
      <Box sx={{ flexGrow: 1, display: "flex" }}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="teal"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{
            display: "block",
          }}
        >
          {
            pages.map((page, index) => (
              <MenuItem key={`navbar-link-${index}`} onClick={handleCloseNavMenu}>
                <Link to={page.link}>
                  <Typography textAlign="center">{page.label}</Typography>
                </Link>
              </MenuItem>
            ))
          }
        </Menu>
      </Box>
      <div className="d-flex align-items-center flex-grow-1 ">
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          color="inherit"
          className=""
          sx={{ marginLeft: "-10px" }}
        >
          <Link to="">
          <AppLogoImage height="30px" width="30px" />
          </Link>
        </IconButton>
        <Link to="">
        <div className="app-title app-color app-font-h2 ">{window.appName}</div>
        </Link>
      </div>
    </>
  );

  const deskNavBar = (
    <>
      <Box sx={{ display: "flex", alignItems: 'center', width: '100%', height:'100%', margin:0, padding:0 }} >
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          color="inherit"
          className="m-0 "
        >
          <Link to="">
            <AppLogoImage height="40px" width="40px" />
          </Link>
        </IconButton>
        <Link to="" style={{ flexGrow: 1 }}>
          <div className="app-title app-color app-font-h3 ">{window.appName}</div>
        </Link>
        {pages.map((page, index) => (
          <Link to={page.link} key={`navbar-button-${index}`}>
            {/* <Button
                className="rounded-5 sparkle-button"
                variant="contained"
                onClick={() => setCurrentComponent(page.label)}
                sx={{ mr: 2, px: 2, backgroundColor: "teal", background: "teal" }}
              >
                {page.label}
              </Button> */}
            <div className="me-1" onClick={updateSelectedComponent}>
              <Typography variant="h6" className={` top-nav-button ${currComponent === page.label && 'top-nav-button-selected'} `}>
                {page.label}
              </Typography>
            </div>

          </Link>
        ))}
        <Link to={"https://play.google.com/store/apps/details?id=com.apnigully.app&pcampaignid=web_share"}>
          <AppDownloadImage />
        </Link>
        {/* {
            !isAuthenticated && <Button
              className="rounded-5 ms-2"
              variant="contained"
              color="warning"
              onClick={()=>{  }}
              sx={{ mr: 2, px: 2, }}
            >
              Get App
              <AndroidIcon />
            </Button>
          } */}
      </Box>

    </>
  );

  const userProfile = (
    <Box sx={{ flexGrow: 0, marginRight: "10px" }}>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar alt="Remy Sharp" src="https://storage.googleapis.com/edstreak/static/website_static_images/Avatar_boy.jpg" />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem key={'userprofile'} onClick={handleCloseUserMenu}>
          <Link to='/userprofile'><Typography textAlign="center">Profile</Typography></Link>
        </MenuItem>
        <MenuItem key={'logout_user'} onClick={() => { logoutFunc(); handleCloseUserMenu(); }}>
          <Typography textAlign="center">Logout</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );

  return (
    <AppBar
      position="static"
      className=" p-0 "
      sx={{ background: "whitesmoke", height: "100%" }}
    >
      <Container maxWidth="xl" className="p-0 h-100 rounded-5" style={{ height: "100%", }}>
        <Toolbar disableGutters className="h-100 p-0 m-0" style={{ height: "100%" }} >
          {isMobileScreen ? mobileNavBar : deskNavBar}
          {isAuthenticated && userProfile}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default MuiNavBar;

const urlToCompMap = {
  'search': 'Search',
  'offers': 'Offers',
  'store-owners-corner': 'Store Manage',
}

const getComponentNameByURL = (urlPath) => {
  let x = urlPath.split('/')
  if (!x[1]){
    return ''
  }
  return urlToCompMap[x[1]] || ''
}

