import React, { useEffect, useState } from 'react';
import { apiPostJson } from '../../../Utilities/API';

export default function OwnersDashboard() {
  // Sample data for demonstration. Replace with actual data or state values.
 
  const [dashboardStats, setDashboardStats] = useState({
    'store_view_count': 0,
    'offer_view_count': 0,
  })
  const fetchDashboardStats = ()=>{
    apiPostJson(
      'ag-api/store-owner/',
      {identifier:'get_dashboard_stats'},
      (response)=>{ 
        setDashboardStats({
          store_view_count: response?.store_view_count || 0,
          offer_view_count:  response?.offer_view_count || 0,
        })
      },
    )
  }

  useEffect(()=>{
    fetchDashboardStats()
  }, [])

  return (
    <div style={styles.container}>
      <div style={styles.statsContainer}>
        <StatCard title="Total Store Clicks" count={dashboardStats.store_view_count} />
        <StatCard title="Total Offer Clicks" count={dashboardStats.offer_view_count} />
      </div>
    </div>
  );
}

function StatCard({ title, count }) {
  return (
    <div style={styles.card}>
      <h2 style={styles.cardTitle}>{title}</h2>
      <p style={styles.cardCount}>{count}</p>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    backgroundColor: '#f5f5f5',
  },
  header: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#333',
  },
  statsContainer: {
    display: 'flex',
    gap: '20px',
    marginTop: '20px',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    width: '200px',
    borderRadius: '8px',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
  },
  cardTitle: {
    fontSize: '18px',
    color: '#555',
    marginBottom: '10px',
  },
  cardCount: {
    fontSize: '28px',
    fontWeight: 'bold',
    color: '#007bff',
  },
};
