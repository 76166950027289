import React from 'react';
import { Container, Typography, Box, Link } from '@mui/material';

const ContactUs = () => {
  return (
    <Container maxWidth="md" style={{marginTop:'20px'}}>
      <Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: 'bold' }}>
        Contact Us
      </Typography>

      <Typography variant="body1" paragraph>
        If you want to register your shop or report an error, contact us on:
      </Typography>

      <Box mb={2}>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Mobile:
        </Typography>
        <Typography variant="body1">
          <Link href="tel:+918879974525" underline="hover" color="primary">
            +918879974525
          </Link>
        </Typography>
      </Box>

      <Box mb={2}>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Email:
        </Typography>
        <Typography variant="body1">
          <Link href="mailto:support@apnigully.com" underline="hover" color="primary">
            support@apnigully.com
          </Link>
        </Typography>
      </Box>

      <Box mb={4} />
    </Container>
  );
};

export default ContactUs;
