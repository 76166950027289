import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const AboutUs = () => {
  return (
    <Container maxWidth="md" style={{marginTop:'20px'}}>
      <Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: 'bold' }}>
        About Us
      </Typography>

      <Typography variant="body1" paragraph>
        Our mission is to provide local business information to the people and take advantage of the technology to simplify this process
        so that barriers between local businesses and their customers can be removed.
      </Typography>

      <Typography variant="body1" paragraph>
        When shop owners register their shop on ApniGully, they can grab the opportunity to be discovered by customers with many essential
        details about their shop.
      </Typography>

      <Box mb={4} />
    </Container>
  );
};

export default AboutUs;
