import React, { useState } from 'react';
import { TextField, Autocomplete, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useSelector } from 'react-redux';

export default function StoreSearchInput(props) {
    const { initSearchText, initSearchType, placeholder } = props;
    const searchKeywordList = useSelector((state) => state.ag.searchKeywordList);
    const [searchData, setSearchData] = useState({
        searchText: initSearchText || '',
        searchType: 'text',
    });

    const [selectedOption, setSelectedOption] = useState(
        initSearchText ? { label: initSearchText } : null
    ); // To track the selected autocomplete option

    // Example autocomplete options (you can fetch this from an API)
    const options = searchKeywordList.map((keyword, index) => ({
        label: keyword,
        id: index,
    }));

    // Handle search
    const handleSearch = () => {
        let search = {};
        if (selectedOption) {
            // If an option is selected from autocomplete
            search = {
                searchText: selectedOption.label,
                searchType: 'keyword',
            };
        } else {
            // If custom text is entered
            search = {
                searchText: searchData.searchText,
                searchType: 'text',
            };
        }
        props.onSearch(search); // Pass searchData containing both text and type
    };

    // Handle key press for Enter key
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <div className="container mt-4">
            <div className="d-flex justify-content-center">
                <Autocomplete
                    freeSolo // Allows free text input along with options
                    options={options}
                    getOptionLabel={(option) => option.label}
                    value={selectedOption} // Controlled value for the selected option
                    onInputChange={(event, newInputValue) => {
                        // Update the searchData object for free text input
                        setSearchData((prev) => ({
                            ...prev,
                            searchText: newInputValue,
                        }));
                        setSelectedOption(null); // Reset the selected option if custom input is given
                    }}
                    onChange={(event, newValue) => {
                        // Set the selected option when an autocomplete suggestion is chosen
                        setSelectedOption(newValue);
                        setSearchData((prev) => ({
                            ...prev,
                            searchText: newValue ? newValue.label : '',
                        }));
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={placeholder || "Search your gully"}
                            variant="outlined"
                            onKeyDown={handleKeyDown} // Detect Enter key press
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <IconButton
                                        onClick={handleSearch}
                                        style={{
                                            backgroundColor: 'teal', // Teal background
                                            color: 'white', // White icon color
                                            padding: '5px',
                                            borderRadius: '50%', // Circular button
                                        }}
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                ),
                            }}
                            sx={{
                                // Change label and input text color to white
                                '& .MuiInputLabel-root': {
                                    color: props.searchBorderColor || 'teal', // Label color
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: props.searchBorderColor || 'teal', // Label color when focused
                                },
                                '& .MuiOutlinedInput-root': {
                                    color: props.searchBorderColor || 'teal', // Input text color
                                    '& fieldset': {
                                        borderColor: props.searchBorderColor || 'teal',
                                        borderWidth: '3px',
                                        borderRadius: '10px',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: props.searchBorderColor || 'teal',
                                        borderRadius: '10px',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: props.searchBorderColor || 'teal',
                                        borderRadius: '10px',
                                    },
                                },
                                '& .MuiOutlinedInput-input': {
                                    color: props.searchBorderColor || 'teal',// Input text color when typing
                                },
                            }}
                        />
                    )}
                    style={{ width: props.width || 400 }}
                />
            </div>
        </div>
    );
}
