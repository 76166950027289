import React from 'react';
import { Container, Typography, List, ListItem, ListItemText, Divider, Box } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md" sx={{marginTop:'20px'}}>
      <Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: 'bold' }}>
        Privacy Policy
      </Typography>

      <Typography variant="subtitle1" gutterBottom align="center" sx={{ fontWeight: 'bold' }}>
        Updated: 21/05/2024
      </Typography>

      <Typography paragraph>
        This privacy policy describes our policies and procedures on the collection, use, and disclosure of your information when you
        use the service and tells you about your privacy rights and how the law protects you. We use your personal data to provide and
        improve the service. By using the service, you agree to the collection and use of information in accordance with this privacy
        policy.
      </Typography>

      <Divider />

      <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
        Definitions
      </Typography>

      <List>
        <ListItem>
          <ListItemText primary="1. Vendor/Shop Owner: A person/company who registered and listed their Shop/services on ApniGully platform." />
        </ListItem>
        <ListItem>
          <ListItemText primary="2. Users: A person who uses ApniGully App to search shops/services listed on ApniGully Platform." />
        </ListItem>
        <ListItem>
          <ListItemText primary="3. Application: Refers to ApniGully platform, (referred to as either 'We', 'Us' or 'Our' in this Agreement)." />
        </ListItem>
        <ListItem>
          <ListItemText primary="4. Device: Means any device that can access the service such as a computer, a cellphone, or a digital tablet." />
        </ListItem>
        <ListItem>
          <ListItemText primary="5. Personal Data: Any information that relates to an identified or identifiable individual." />
        </ListItem>
        <ListItem>
          <ListItemText primary="6. Service: Refers to the Application." />
        </ListItem>
        <ListItem>
          <ListItemText primary="7. Service Provider: Any natural or legal person who processes the data on behalf of ApniGully." />
        </ListItem>
        <ListItem>
          <ListItemText primary="8. Usage Data: Refers to data collected automatically, either generated by the use of the service or from the service infrastructure itself (e.g., the duration of a page visit)." />
        </ListItem>
      </List>

      <Divider />

      <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
        Data Collection
      </Typography>

      <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
        Personal Data
      </Typography>

      <Typography paragraph>
        To provide services, ApniGully collects the following information either online or offline:
      </Typography>

      <Typography paragraph sx={{ fontWeight: 'bold' }}>
        1. From vendor/shop owners:
      </Typography>

      <List sx={{ pl: 4 }}>
        <ListItem>
          <ListItemText primary="• Name of the Shop." />
        </ListItem>
        <ListItem>
          <ListItemText primary="• Email Address." />
        </ListItem>
        <ListItem>
          <ListItemText primary="• Website." />
        </ListItem>
        <ListItem>
          <ListItemText primary="• Contact details of the shop owner." />
        </ListItem>
        <ListItem>
          <ListItemText primary="• Address of the shop." />
        </ListItem>
        <ListItem>
          <ListItemText primary="• Images/Videos of the shop." />
        </ListItem>
        <ListItem>
          <ListItemText primary="• Operational time of the shop." />
        </ListItem>
      </List>

      <Typography paragraph sx={{ fontWeight: 'bold' }}>
        2. From Users:
      </Typography>

      <Typography paragraph>
        ApniGully platform does not collect or store any type of personal data from Users.
      </Typography>

      <Divider />

      <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
        Usage Data
      </Typography>

      <Typography paragraph sx={{ fontWeight: 'bold' }}>
        1. From vendor/shop owners:
      </Typography>

      <Typography paragraph>
        ApniGully platform does not collect or store any type of usage data from shop owners.
      </Typography>

      <Typography paragraph sx={{ fontWeight: 'bold' }}>
        2. From Users:
      </Typography>

      <Typography paragraph>
        ApniGully platform does not collect or store any type of usage data from users, either online or offline.
      </Typography>

      <Divider />

      <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
        Information Collected while Using the Application
      </Typography>

      <Typography paragraph sx={{ fontWeight: 'bold' }}>
        1. From vendor/shop owners:
      </Typography>

      <Typography paragraph>
        ApniGully platform does not collect or store any type of information from shop owners while using the ApniGully Application.
      </Typography>

      <Typography paragraph sx={{ fontWeight: 'bold' }}>
        2. From Users:
      </Typography>

      <Typography paragraph>
        While using ApniGully Application, in order to provide features of our Application, we may collect, with your prior permission, information regarding your location.
      </Typography>

      <Typography paragraph>
        We use this information to provide features of our service, to improve and customize our service. The information may be uploaded to the Company's servers and/or a service Provider's server or it may be simply stored on your device. You can enable or disable access to this information at any time, through your Device settings.
      </Typography>

      <Divider />

      <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
        Use of Your Personal Data
      </Typography>

      <List>
        <ListItem>
          <ListItemText primary="1. To list your shop on the ApniGully platform." />
        </ListItem>
        <ListItem>
          <ListItemText primary="2. To provide and maintain our services." />
        </ListItem>
        <ListItem>
          <ListItemText primary="3. To manage your account registration as a user of the service." />
        </ListItem>
        <ListItem>
          <ListItemText primary="4. To contact you by email, telephone calls, or SMS regarding updates or informative communications." />
        </ListItem>
        <ListItem>
          <ListItemText primary="5. To manage your requests." />
        </ListItem>
        <ListItem>
          <ListItemText primary="6. For other purposes, such as data analysis, identifying usage trends, determining the effectiveness of promotional campaigns, and improving our service." />
        </ListItem>
      </List>

      <Divider />

      <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
        Retention of Your Personal Data
      </Typography>

      <Typography paragraph>
        ApniGully will retain your personal data only for as long as is necessary for the purposes set out in this privacy policy. We will retain and use your personal data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
      </Typography>

      <Typography paragraph>
        ApniGully will also retain usage data for internal analysis purposes. Usage data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our service, or we are legally obligated to retain this data for longer periods.
      </Typography>

      <Divider />

      <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
        Transfer of Your Personal Data
      </Typography>

      <Typography paragraph>
        Your information, including personal data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to and maintained on computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those in your jurisdiction.
      </Typography>

      <Typography paragraph>
        Your consent to this privacy policy followed by your submission of such information represents your agreement to that transfer.
      </Typography>

      <Divider />

      <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
        Delete Your Personal Data
      </Typography>

      <Typography paragraph>
        You have the right to delete or request that we assist in deleting the personal data that we have collected about you. Our service may give you the ability to delete certain information about you from within the service.
      </Typography>

      <Typography paragraph>
        You may update, amend, or delete your information at any time by signing in to your account, if you have one, and visiting the account settings section that allows you to manage your personal information.
      </Typography>

      <Divider />

      <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
        Disclosure of Your Personal Data
      </Typography>

      <Typography paragraph sx={{ fontWeight: 'bold' }}>
        Law Enforcement:
      </Typography>

      <Typography paragraph>
        Under certain circumstances, ApniGully may be required to disclose your personal data if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).
      </Typography>

      <Typography paragraph sx={{ fontWeight: 'bold' }}>
        Other Legal Requirements:
      </Typography>

      <List sx={{ pl: 4 }}>
        <ListItem>
          <ListItemText primary="• Comply with a legal obligation" />
        </ListItem>
        <ListItem>
          <ListItemText primary="• Protect and defend the rights or property of the Company" />
        </ListItem>
        <ListItem>
          <ListItemText primary="• Prevent or investigate possible wrongdoing in connection with the service" />
        </ListItem>
        <ListItem>
          <ListItemText primary="• Protect the personal safety of Users of the service or the public" />
        </ListItem>
        <ListItem>
          <ListItemText primary="• Protect against legal liability" />
        </ListItem>
      </List>

      <Divider />

      <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
        Security of Your Personal Data
      </Typography>

      <Typography paragraph>
        The security of your personal data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
      </Typography>

      <Divider />

      <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
        Children's Privacy
      </Typography>

      <Typography paragraph>
        Our service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13.
      </Typography>

      <Typography paragraph>
        If you are a parent or guardian and you are aware that your child has provided us with personal data, please contact us. If we become aware that we have collected personal data from anyone under the age of 13 without verification of parental consent, we take steps to remove that information from our servers.
      </Typography>

      <Divider />

      <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
        Links to Other Websites
      </Typography>

      <Typography paragraph>
        Our service may contain links to other websites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the privacy policy of every site you visit.
      </Typography>

      <Divider />

      <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
        Changes to This Privacy Policy
      </Typography>

      <Typography paragraph>
        We may update our privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page.
      </Typography>

      <Divider />

      <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
        Contact Us
      </Typography>

      <Typography paragraph>
        If you have any questions about this privacy policy, you can contact us at:
        <br />
        Email: <strong>support@apnigully.com</strong>
        <br />
        By phone number: <strong>+91-8879974525</strong>
      </Typography>

      <Box mb={4} />
    </Container>
  );
};

export default PrivacyPolicy;
