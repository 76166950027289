import {createSlice} from '@reduxjs/toolkit'

export const agSlice = createSlice({
    name:'ag',
    initialState:{
        userLocation:{
            latitude: null,
            longitude: null,
            location_object: null,
            error: null,
            location_attempted: false
        },
        searchKeywordList:[],
    },
    reducers:{
        updateUserLocation:(state, action)=>{
            console.log(action.payload?.userLocation)
            state.userLocation = {...state.userLocation, ...action.payload.userLocation}
        },
        updateSearchKeywordList:(state, action)=>{
            console.log(action.payload?.searchKeywordList)
            state.searchKeywordList = action.payload.searchKeywordList
        }
    }
})

export const {
    updateUserLocation,
    updateSearchKeywordList
} = agSlice.actions

export default agSlice.reducer