import { Container } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "./CommonComponents/Navbar";
import AppRoutes from "./AGRoutes";
import AppFooter from "./CommonComponents/Footer/AppFooter";
import { useEffect } from "react";
import { apiGetJson, apiPostJson } from "../Utilities/API";
import { updateSearchKeywordList, updateUserLocation } from "../store/agSlice";
import { updateUserData, updateUserLoginStatus } from "../store/userSlice";
import { useLocation } from "react-router-dom";


export default function ApniGullyApp() {
  const appWrapperCSSClass = useSelector(state => state.ui.appWrapperCSSClass)
  const appWrapperInlineStyle = useSelector(state => state.ui.appWrapperInlineStyle)
  const dispatch = useDispatch()
  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          dispatch(updateUserLocation({userLocation:{
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            error: null,
            location_attempted: true,
          }}))
        },
        (error) => {
            dispatch(updateUserLocation({userLocation:{
                latitude: null,
                longitude: null,
                error: error.message ,
                location_attempted: true,
        }}))
        }
      );
    } else {
        dispatch(updateUserLocation({userLocation:{
            latitude: null, 
            longitude: null, 
            error: 'Geolocation is not supported by your browser.' ,
            location_attempted: true,
        }}))
    }
  };

  const getKeywordList = ()=>{
    apiPostJson(
        'ag-api/stores/',
        {identifier:'get_search_keyword_list'},
        (response)=>{ 
            if(Array.isArray(response?.search_keyword_list)){
                dispatch(updateSearchKeywordList({searchKeywordList:response.search_keyword_list}))
            }
        },
        ()=>{}
    )
  }

  const fetchUserInfo = ()=>{
    apiGetJson(
      'ag-api/userinfo/',
      (response)=>{
        if(response?.userinfo?.mobile){
          dispatch(updateUserLoginStatus({isLoggedIn:true}))
          dispatch(updateUserData({userData: response.userinfo}))
        }
        else{
          console.log('Userinfo not received')
        }
      },
      (response)=>{ window.log(response) }
    )
  }

  const fetchAdminInfo = ()=>{
    apiGetJson(
      'ag-api/admininfo/',
      (response)=>{
        if(response?.userinfo?.mobile){
          dispatch(updateUserLoginStatus({isLoggedIn:true}))
          dispatch(updateUserData({
            userData: {
              email: response?.userinfo.email,
              user_id: response?.userinfo.user_id,
              mobile: response?.userinfo.mobile,
              user_type: response?.userinfo.user_type
            }
          }))
        }
        else{
          console.log('Admin info not received')
          fetchUserInfo()
        }
      },
      (response)=>{ window.log(response); fetchUserInfo() }
    )
  }

  useEffect(()=>{
    getUserLocation()
    getKeywordList()
    fetchAdminInfo()
  },[])

  const location = useLocation()

  const DeskTopScreen = (
    <div
      id='top_wrapper'
      className={`w-100 ${appWrapperCSSClass} app-bg-body`}
      style={{ height: "100vh", overflow: "auto", ...appWrapperInlineStyle }}
    >
      <div className=" w-100 h-100">
        <div className={` w-100 app-navbar `} style={{ zIndex: window.elementZIndex['navbar'] }}>
          <NavBar/>
        </div>
        <div className={` app-body1 `} style={{ 'minHeight': '700px', width: '100%', }}>
          <Container maxWidth="xl">
            <AppRoutes />
          </Container>
        </div>
        { !( (window.isDeviceTablet || window.isDeviceMobile) && (location.pathname!=='' && location.pathname!=='/') ) && <div className="w-100 bg-light">
          <Container maxWidth="xl">
            <AppFooter />
          </Container>
        </div>}
      </div>
    </div>
  );
  return DeskTopScreen;
}
