import React, { useEffect, useState } from 'react';
import { apiPostJson } from '../../Utilities/API';
import { Card, CardContent, Typography, Grid, Box, Button, CardMedia, Modal, Fade } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OfferDetails from './ParticularOfferDetails';

const placeholderImages = [
    '/uploads/offer_banners/default1.avif',
    '/uploads/offer_banners/default2.webp',
    '/uploads/offer_banners/default3.webp'
];
const pastelColors = ['#FFEBEE', '#E3F2FD', '#E8F5E9', '#FFF3E0', '#F3E5F5'];

export default function Offers(props) {
    const [offerList, setOfferList] = useState([]);
    const [page, setPage] = useState(0);
    const [pageSize] = useState(12);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [selectedOffer, setSelectedOffer] = useState(null);

    const navigate = useNavigate();

    const fetchOfferList = (currentPage) => {
        setLoading(true);
        apiPostJson(
            'ag-api/offers/',
            { identifier: 'get_offers_list', page: currentPage, pageSize },
            (response) => {
                if (response.offers_list && response.offers_list.length > 0) {
                    setOfferList((prevOffers) => [...prevOffers, ...response.offers_list]);
                    setHasMore(response.offers_list.length === pageSize);
                } else {
                    setHasMore(false);
                }
                setLoading(false);
            },
            (response) => {
                setLoading(false);
            }
        );
    };

    useEffect(() => {
        setOfferList([]);
        setPage(0);
        setHasMore(true);
        fetchOfferList(0);
    }, []);

    const loadMoreOffers = () => {
        const nextPage = page + 1;
        setPage(nextPage);
        fetchOfferList(nextPage);
    };

    const handleCopyCode = (code) => {
        navigator.clipboard.writeText(code);
        alert('Code copied to clipboard');
    };

    const handleOpenModal = (offer) => {
        setSelectedOffer(offer);
        window.history.pushState({ modalOpen: true }, '', '?modal=true'); // Add a unique state to history
    };

    const handleCloseModal = () => {
        setSelectedOffer(null);
        window.history.replaceState(null, '', window.location.pathname); // Remove modal state from history
    };

    useEffect(() => {
        const handlePopState = () => {
            if (selectedOffer) {
                setSelectedOffer(null);
            }
        };

        // Add event listener for `popstate` when component mounts
        window.addEventListener('popstate', handlePopState);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [selectedOffer]);

    const renderCard = (offer) => {
        const hasImage = Boolean(offer.banner_image);
        const imageSrc = hasImage 
            ? window.getMediaImageURL(offer.banner_image) 
            : placeholderImages[Math.floor(Math.random() * placeholderImages.length)];
        const backgroundColor = pastelColors[Math.floor(Math.random() * pastelColors.length)];
    
        return (
            <Card
                sx={{
                    maxWidth: 345,
                    height: 250,
                    borderRadius: '12px',
                    boxShadow: 3,
                    cursor: 'pointer',
                    transition: 'transform 0.2s ease-in-out',
                    '&:hover': { transform: 'scale(1.03)' },
                    backgroundColor: hasImage ? 'transparent' : backgroundColor,
                    display: 'flex',
                    flexDirection: hasImage ? 'column' : 'row',
                }}
                onClick={() => handleOpenModal(offer)} // Open modal on click
            >
                {hasImage ? (
                    <Box>
                        <CardMedia
                            component="img"
                            height="150"
                            image={imageSrc}
                            alt={offer.offer_title}
                            sx={{ borderRadius: '12px 12px 0 0' }}
                        />
                        <CardContent sx={{ textAlign: 'center', padding: '8px' }}>
                            <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', color: '#333' }}>
                                {offer.store_name}
                            </Typography>
                        </CardContent>
                    </Box>
                ) : (
                    <Box sx={{ display: 'flex', width: '100%', padding: '16px' }}>
                        {/* Discount and details section */}
                        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', color: '#333' }}>
                                {offer.discount_type === '%' ? `${parseInt(offer.discount)}% OFF` : `₹${parseInt(offer.discount)} OFF`}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" sx={{ marginBottom: '8px' }}>
                                {offer.discount_type === '%' ? `${parseInt(offer.discount)}% Discount` : `Flat ₹${parseInt(offer.discount)} OFF`} | {offer.min_purchase ? `Min txn. of ₹${offer.min_purchase}` : 'No min. purchase'}
                            </Typography>
    
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginBottom: '8px' }}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold', border: '1px solid #ddd', padding: '4px 8px', borderRadius: '4px', color: '#333' }}>
                                    {offer.discount_code}
                                </Typography>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    startIcon={<ContentCopyIcon fontSize="small" />}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleCopyCode(offer.discount_code);
                                    }}
                                >
                                    Copy
                                </Button>
                            </Box>
                            <Typography variant="body2" color="text.secondary">
                                Valid till {new Date(offer.expiry_date).toLocaleDateString()}
                            </Typography>
                            <Box sx={{ textAlign: 'right', marginTop: '8px' }}>
                                {hasImage ? (
                                    <Typography variant="body2" sx={{ color: '#ff6d00', cursor: 'pointer' }} onClick={(e) => {
                                        e.stopPropagation();
                                        navigate(`/offer-details/?offerID=${offer.offer_id}`);
                                    }}>
                                        Know more
                                    </Typography>
                                ) : (
                                    <Box sx={{marginLeft:0, paddingLeft:0}}>
                                        <Typography variant="body" sx={{ color: '#333', fontWeight: 'bold', textAlign: 'left' }}>
                                            {offer.offer_title}, {offer.store_name}
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                        <Box sx={{ width: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '16px' }}>
                            <CardMedia
                                component="img"
                                image={window.getMediaImageURL(imageSrc)}
                                alt="Placeholder"
                                sx={{ width: '100%', borderRadius: '8px' }}
                            />
                        </Box>
                    </Box>
                )}
            </Card>
        );
    };

    return (
        <Box sx={{ padding: '20px' }}>
            <Grid container spacing={3}>
                {offerList.map((offer) => (
                    <Grid item xs={12} sm={6} md={4} key={offer.offer_id}>
                        {renderCard(offer)}
                    </Grid>
                ))}
            </Grid>
            {hasMore && (
                <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
                    <Button variant="contained" onClick={loadMoreOffers} disabled={loading}>
                        {loading ? 'Loading...' : 'Load More'}
                    </Button>
                </Box>
            )}

            {/* Modal for Offer Details */}
            <Modal open={!!selectedOffer} onClose={handleCloseModal} closeAfterTransition>
                <Fade in={!!selectedOffer}>
                    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 500, maxWidth:'100vw', maxHeight:'100vh', overflow:'auto', bgcolor: 'background.paper', borderRadius: 2, boxShadow: 24, }}>
                        {selectedOffer && <OfferDetails offer={selectedOffer} onClose={handleCloseModal}/>}
                    </Box>
                </Fade>
            </Modal>
        </Box>
    );
}
