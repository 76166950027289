import { useMediaQuery } from "react-responsive";

export function useSetupUI() {
  window.isDeviceTablet = useMediaQuery({ query: "(max-width: 1224px)" });
  window.isDeviceMobile = useMediaQuery({ query: "(max-width: 768px)" })
  window.isDevicePortrait = useMediaQuery({ query: "(orientation: portrait)" });

  window.appName = 'ApniGully'
  window.uiConfig = { appColor: "teal" };  
  window.elementZIndex = {
    'navbar':10,
  }
return true
}
