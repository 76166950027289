import React, { useEffect, useState } from 'react';
import { TextField, Button, Typography, Box, Alert } from '@mui/material';
import { apiPostJson } from '../../../Utilities/API';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserData, updateUserLoginStatus } from '../../../store/userSlice';
import { useNavigate } from 'react-router-dom';

const ManagerLogin = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector(state=>state.user)
    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')
    const handleLogin = async () => {
        apiPostJson(
            'ag-api/ag-admin-login/',
            { identifier: 'manager-login', mobile, password },
            (response) => {
                console.log('succesfully logged in',response, response.token)
                if(response.token){
                    localStorage.setItem('jwtToken', response.token); 
                    dispatch(updateUserData({ userData:{mobile: mobile, user_type:'admin' }}))
                    dispatch(updateUserLoginStatus({isLoggedIn:true}))
                    console.log('navigating')
                    navigate('/ag-admin/')
                }
            },
            (response) => {
                setError('Failed to login. Please try again.');
            }
        );
    };

    useEffect(() => {
      if(user.isLoggedIn && user?.userData?.user_type === 'admin'){
        navigate('/ag-admin')
      }
    }, [user]);

    return (
        <Box sx={{ maxWidth: 400, margin: 'auto', mt: 8 }}>
            <Typography variant="h4" gutterBottom align="center">
                Admin Login
            </Typography>
            {/* {JSON.stringify(user)} */}
            {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

            {message && <Alert severity="success" sx={{ mb: 2 }}>{message}</Alert>}
            <TextField
                        label="Mobile Number"
                        variant="outlined"
                        fullWidth
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                        sx={{ mb: 2 }}
            />
            <TextField
                        label="Password"
                        variant="outlined"
                        fullWidth
                        value={password}
                        type='password'
                        onChange={(e) => setPassword(e.target.value)}
                        sx={{ mb: 2 }}
            />
            <Button variant="contained" fullWidth onClick={handleLogin}>
                        Login
                    </Button>
        </Box>
    );
};

export default ManagerLogin;
