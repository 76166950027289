import React from "react";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";
import { AppLogoImage } from "../ImagesComponent";
import { Link } from "react-router-dom";

export default function AppFooter() {
  return (
    <MDBFooter bgColor="light" className="text-center text-lg-start text-muted">
      <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        <div className="me-5 d-none d-lg-block">
          <span>Get connected with us on social networks:</span>
        </div>
        <div>
          <a href="https://www.facebook.com/share/Qb3PpbHUJxJutxCa/" className="me-4 text-reset">
            <MDBIcon fab icon="facebook-f" />
          </a>
          {/* <a href="" className="me-4 text-reset">
            <MDBIcon fab icon="twitter" />
          </a> */}
          <a href="https://youtube.com/@apnigully?si=WgDsTSIefwkY4SQz" className="me-4 text-reset">
            <MDBIcon fab icon="youtube" />
          </a>
          <a href="https://www.instagram.com/apni.gully/profilecard/?igsh=MXVzY2cwZmFiN2ZyNw==" className="me-4 text-reset">
            <MDBIcon fab icon="instagram" />
          </a>
          {/* <a href="" className="me-4 text-reset">
            <MDBIcon fab icon="linkedin" />
          </a> */}
          {/* <a href="" className="me-4 text-reset">
            <MDBIcon fab icon="github" />
          </a> */}
        </div>
      </section>

      <section className="">
        <div className="w-100 text-center text-md-start mt-5">
          <MDBRow className="mt-3">
            <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4 d-flex align-items-center">
                <div className="me-3">
                  <AppLogoImage />
                </div>
                {window.appName}
              </h6>
              <p className="text-left" style={{textAlign:'justify'}}>
              ApniGully connects local businesses with nearby customers, providing essential shop details, offers, and navigation. Shop owners can easily register their shops and be discovered by local customers, bridging the gap between businesses and the community.
              </p>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Modules</h6>
              {/* <p>
                <Link to="/register-shop" className="text-reset">
                  Register Shop
                </Link>
              </p> */}
              <p>
              <Link to="/store-owners-corner" className="text-reset">
                  Shop Owner's Corner
                </Link>
              </p>
              <p>
              <Link to="/offers" className="text-reset">
                  Offers
                </Link>
              </p>
              <p>
              <Link to="/search" className="text-reset">
                  Search shops
                </Link>
              </p>
            </MDBCol>

            <MDBCol md="3" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Useful links</h6>

              <p>
              <Link to="/privacy-policy" className="text-reset">
                  Privacy Policy
                </Link>
              </p>
              <p>
              <Link to="/terms-conditions" className="text-reset">
                  Terms & Conditions
                </Link>
              </p>
              <p>
              <Link to="/about" className="text-reset">
                  About Us
                </Link>
              </p>
              <p>
              <Link to="/faq" className="text-reset">
                  FAQ
                </Link>
              </p>
              <p>
              <Link to="/contact" className="text-reset">
                  Contact Us
                </Link>
              </p>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
              {/* <p>
                <MDBIcon icon="home" className="me-3" />
                Mumbai, Maharashtra, India
              </p> */}
              <p>
                <MDBIcon icon="envelope" className="me-3" />
                support@apnigully.com
              </p>
              <p>
                <MDBIcon icon="phone" className="me-3" />+91-8879974525
              </p>
            </MDBCol>
          </MDBRow>
        </div>
      </section>

      <div
        className="text-center p-4"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
      >
        © 2024 Copyright:
        <a className="text-reset fw-bold" href="https://apnigully.com/">
          apnigully.com
        </a>
      </div>
    </MDBFooter>
  );
}
