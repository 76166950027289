import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import StoreOwnersCorner from './StoreOwner';

export default function StoreOwners(props) {
    const navigate = useNavigate()
    const isLoggedIn = useSelector(state => state.user.isLoggedIn)

    useEffect(() => { 
        if (!isLoggedIn) {
            navigate('/store-owner-login')
        }
    }, [])

    return isLoggedIn && <StoreOwnersCorner {...props} />
}