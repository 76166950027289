import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function DeleteButtonWithWarning({ message, onDelete }) {
    const [open, setOpen] = useState(false);

    // Function to open the confirmation dialog
    const handleOpen = () => {
        setOpen(true);
    };

    // Function to close the confirmation dialog
    const handleClose = () => {
        setOpen(false);
    };

    // Function to handle the delete action
    const handleConfirmDelete = () => {
        onDelete(); // Call the provided onDelete function
        setOpen(false); // Close the dialog after deletion
    };

    return (
        <div>
            {/* Delete Button */}
            <Button 
                variant="contained" 
                color="error" 
                startIcon={<DeleteIcon />} 
                onClick={handleOpen}
            >
                Delete
            </Button>

            {/* Confirmation Dialog */}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message || "Are you sure you want to delete this item? This action cannot be undone."}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} color="error" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default DeleteButtonWithWarning;
