import axios from 'axios';
import { getCookie } from '../LocalStorage';

// Global API base URL
const API_BASE_URL = process.env.NODE_ENV === "production" ? process.env.REACT_APP_PROD_API_BASE_URL :process.env.REACT_APP_DEV_API_BASE_URL
const API_KEY = 'AG-8qenc8zfufIdrU7JMavrsZtIrUPzAm98';  // API key for website

// Helper function to construct the URL correctly
const constructURL = (urlSuffix) => {
  return urlSuffix
  // Ensure there is exactly one `/` between base URL and suffix
  return `${API_BASE_URL.replace(/\/+$/, '')}/${urlSuffix.replace(/^\/+/, '')}`;
};


// Create a central apiClient with Axios
const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRFToken': getCookie('csrftoken'),
    'AG-API-KEY': API_KEY
  },
});

apiClient.interceptors.request.use(
  (config) => {
      const token = localStorage.getItem('jwtToken');
      if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
  },
  (error) => {
      return Promise.reject(error);
  }
);
// Add request and response interceptors
apiClient.interceptors.response.use(
  (response) => {
    // Any status code within the range of 2xx will cause this function to trigger
    return response;
  },
  (error) => {
    // Any status codes outside of the range of 2xx will cause this function to trigger
    if (error.response) {
      logError(error.response.data, error.config.url, 'Error at server');
      return Promise.reject(error.response.data); // Return error response data
    } else {
      logError(error, error.config.url, 'Error in processing request');
      return Promise.reject(error); // Return entire error
    }
  }
);

// GET request
export const apiGetJson = function (
  urlSuffix,
  successfulResponseHandler,
  unsuccessfulResponseHandler = logError
) {
  const url = constructURL(urlSuffix);
  apiClient
    .get(url)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        successfulResponseHandler(response.data);
      } else {
        unsuccessfulResponseHandler && unsuccessfulResponseHandler(response.data);
      }
    })
    .catch((error) => {
      unsuccessfulResponseHandler && unsuccessfulResponseHandler(error);
    });
};

// POST request with JSON body
export const apiPostJson = function (
  urlSuffix,
  requestBody,
  successfulResponseHandler,
  unsuccessfulResponseHandler
) {
  const url = constructURL(urlSuffix);
  apiClient
    .post(url, requestBody)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        successfulResponseHandler(response.data);
      } else {
        console.log('response else ', response.data)
        unsuccessfulResponseHandler && unsuccessfulResponseHandler(response.data);
      }
    })
    .catch((error) => {
      console.log('response error1 _', error, {flag: Boolean(unsuccessfulResponseHandler)})
      if(Boolean(unsuccessfulResponseHandler)){
        unsuccessfulResponseHandler(error);
      }
       console.log('response error2 _', error, {flag: Boolean(unsuccessfulResponseHandler)})
    });
};

// POST request with FormData
export const apiPostFormdata = function (
  urlSuffix,
  formData,
  successfulResponseHandler,
  unsuccessfulResponseHandler = logError
) {
  const url = constructURL(urlSuffix);
  apiClient
    .post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Let Axios set Content-Type automatically
      },
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        successfulResponseHandler(response.data);
      } else {
        unsuccessfulResponseHandler && unsuccessfulResponseHandler(response.data);
      }
    })
    .catch((error) => {
      unsuccessfulResponseHandler && unsuccessfulResponseHandler(error);
    });
};

// POST request for file download
export const apiPostFileDownload = function (
  urlSuffix,
  requestBody,
  successfulResponseHandler,
  unsuccessfulResponseHandler = logError
) {
  const url = constructURL(urlSuffix);
  apiClient
    .post(url, requestBody, {
      headers: {
        Accept: 'application/octet-stream',
        'Content-Type': 'application/json',
      },
      responseType: 'blob', // Expect blob data for file download
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        successfulResponseHandler(blob);
      } else {
        unsuccessfulResponseHandler && unsuccessfulResponseHandler(response.data);
      }
    })
    .catch((error) => {
      unsuccessfulResponseHandler && unsuccessfulResponseHandler(error);
    });
};

// Log errors for debugging
const logError = (response, url = '', description = '') => {
  console.error({
    'Error at URL': url,
    description: description,
    response: response,
  });
};
