import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import OwnersDashboard from './OwnersDashboard';
import OwnersStores from './OwnersStores';
import OwnersOffers from './OwnersOffers';
import { useDispatch, useSelector } from 'react-redux';
import OwnersOfferCreator from './CreateOffer';
import { apiPostJson } from '../../Utilities/API';
import { Box, Button, Typography } from '@mui/material';
import { updateUserData, updateUserLoginStatus } from '../../store/userSlice';

export default function StoreOwnersCorner(props) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userData = useSelector(state => state.user.userData)
    const isLoggedIn = useSelector(state => state.user.isLoggedIn)

    const [storeList, setStoreList] = useState([])

    const fetchCurOwnersStore = () => {
        apiPostJson(
            'ag-api/store-owner/',
            { identifier: 'get_owners_store_list', mobile: userData.mobile },
            (response) => {
                if (Array.isArray(response?.owners_store_list)) {
                    setStoreList(response.owners_store_list)
                }
            },
            (response) => { }
        )
    }

    useEffect(() => {
        if (userData.mobile) {
            fetchCurOwnersStore()
        }
    }, [userData.mobile])

    const logout = () => {
        // setStep('success');
        localStorage.removeItem('jwtToken');
        dispatch(updateUserData({ userData: { mobile: null } }))
        dispatch(updateUserLoginStatus({ isLoggedIn: false }))
        navigate('/store-owner-login/')
    }

    const isDeviceMobile = window.isDeviceMobile

    return (
        <div>
            {/* <Box display="flex" alignItems="center" className='mt-4'>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ mx: 2 }}
                    onClick={() => navigate('/store-owners-corner/create-offer')}
                >
                    Create New Offer
                </Button>
                {isLoggedIn && userData?.mobile && (
                    <><Typography variant="h6" sx={{ ml: 3, mr: 3 }}>
                        Logged in with: {userData.mobile}
                    </Typography>
                        <Button variant='outlined' onClick={logout} >Logout</Button>
                    </>
                )}
            </Box> */}
            <Box
                display="flex"
                alignItems="center"
                flexDirection={isDeviceMobile ? 'column' : 'row'} // Stack vertically on mobile
                className="mt-4"
                sx={{
                    gap: isDeviceMobile ? 2 : 3, // Add vertical space for mobile layout
                    justifyContent: isDeviceMobile ? 'center' : 'flex-start', // Center on mobile
                    textAlign: isDeviceMobile ? 'center' : 'left'
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ mx: isDeviceMobile ? 0 : 2, width: isDeviceMobile ? '100%' : 'auto' }}
                    onClick={() => navigate('/store-owners-corner/create-offer')}
                >
                    Create New Offer
                </Button>
                {isLoggedIn && userData?.mobile && (
                    <>
                        <Typography
                            variant="h6"
                            sx={{
                                ml: isDeviceMobile ? 0 : 3,
                                mr: isDeviceMobile ? 0 : 3,
                                mt: isDeviceMobile ? 1 : 0,
                                fontSize: isDeviceMobile ? '1rem' : '1.25rem' // Adjust font size for mobile
                            }}
                        >
                            Logged in with: {userData.mobile}
                        </Typography>
                        <Button
                            variant="outlined"
                            onClick={logout}
                            sx={{
                                width: isDeviceMobile ? '100%' : 'auto', // Full width for mobile
                                mt: isDeviceMobile ? 1 : 0
                            }}
                        >
                            Logout
                        </Button>
                    </>
                )}
            </Box>
            <Routes>
                <Route path="" element={<>
                    <OwnersDashboard storeList={storeList} />
                    <OwnersStores storeList={storeList} />
                    <OwnersOffers storeList={storeList} />
                </>}
                />
                <Route path="create-offer/*" element={<OwnersOfferCreator storeList={storeList} />} />
            </Routes>
        </div>
    );
}