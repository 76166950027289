import {createSlice} from '@reduxjs/toolkit'


export const uiSlice = createSlice({
    name:'ui',
    initialState:{
        isLoginModalDisplayed:false,
        snackBarMessage:{},
        appWrapperCSSClass:' homepage-bg', //stationary_background
        appWrapperInlineStyle:''
    },
    reducers:{
        showLoginModal:(state, action)=>{
            state.isLoginModalDisplayed = true
        },
        closeLoginModal:(state, action)=>{
            state.isLoginModalDisplayed = false
        },
        showSnackBar:(state, action)=>{
            state.snackBarMessage = action.payload
        },
        closeSnackBar:(state, action)=>{
            state.snackBarMessage = null
        },
        updateAppWrapperStyle:(state, action)=>{
            if(action.payload.cssClass){
                state.appWrapperCSSClass = action.payload.cssClass
            }
            if(action.payload.inlineStyle){
                state.appWrapperInlineStyle = action.payload.inlineStyle
            }
        }
    }
})

export const {
    showLoginModal,
    closeLoginModal,
    showSnackBar,
    closeSnackBar,
    updateAppWrapperStyle
} = uiSlice.actions

export default uiSlice.reducer