import React from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ = () => {
  return (
    <Container maxWidth="md" style={{marginTop:'20px'}}>
      <Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: 'bold' }}>
        Frequently Asked Questions
      </Typography>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="h6">1. What is ApniGully?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            ApniGully is a platform where users can search information about the shops in their neighborhood.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography variant="h6">2. How to register a shop?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography paragraph>
            There are two ways to register a shop:
          </Typography>
          <Typography paragraph>
            <strong>i. Offline:</strong> Shop owners can reach ApniGully with their details to register the shop.
          </Typography>
          <Typography>
            <strong>ii. Online:</strong> Shop owners can download and log in to the App using their mobile number and register the shop.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
          <Typography variant="h6">3. How to modify information about my shop?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography paragraph>
            There are two ways to modify a registered shop:
          </Typography>
          <Typography paragraph>
            <strong>i. Offline:</strong> Shop owners can ask ApniGully to modify and update the details of the shop.
          </Typography>
          <Typography>
            <strong>ii. Online:</strong> Shop owners can download the app from the Playstore and log in to the App using the mobile number with which the shop is registered and modify the shop.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4a-content" id="panel4a-header">
          <Typography variant="h6">4. How to delete shop information completely from ApniGully Platform?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography paragraph>
            At any time, shop owners can remove all the information of a shop from ApniGully. There are two ways to do it:
          </Typography>
          <Typography paragraph>
            <strong>i. Offline:</strong> Shop owners can approach ApniGully (using the contact details provided in the "Contact Us" section) and ask to remove the information.
          </Typography>
          <Typography>
            <strong>ii. Online:</strong> Shop owners can download the app from Playstore and log in using the same mobile number with which the shop is registered. After login, go to the “Manage Shop” section and click on View/Modify shop option, then delete the shop(s) from the list of registered shops.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5a-content" id="panel5a-header">
          <Typography variant="h6">5. What details are available about a shop?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography paragraph>
            ApniGully provides the following details about a shop:
          </Typography>
          <ul>
            <li>Name of the Shop</li>
            <li>Address of the Shop with MAP navigation</li>
            <li>Contact details of the Shop</li>
            <li>Operational timing of the Shop</li>
          </ul>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel6a-content" id="panel6a-header">
          <Typography variant="h6">6. How can I report if any information about a shop is incorrect?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            ApniGully presents information about a shop on a best-effort basis and there may be some incorrect information. Users are welcome to point out mistakes, and this can be done on the shop details page, where a “Report an Issue” option is available.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Box mb={4} />
    </Container>
  );
};

export default FAQ;
