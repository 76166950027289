import React, { useState, useEffect } from 'react';
import { apiPostJson } from '../../Utilities/API';
import { Button, Card, CardMedia, CardContent, Typography, Grid, LinearProgress, CircularProgress, IconButton } from '@mui/material';
import SearchInput from './SearchInput';
import { useSelector } from 'react-redux';
import ShopPlaceholderImage from '../CommonComponents/ImagesComponent/StaticImages/ShopSamples/store_placeholder.png';
import { Schedule as ScheduleIcon, LockClock as LockClockIcon, Room as LocationIcon, Directions as DirectionsIcon, Phone as PhoneIcon, HourglassEmpty } from '@mui/icons-material';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function Search(props) {
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const searchText = searchParams.get('searchText');
    const searchType = searchParams.get('searchType');

    const userLocation = useSelector(state => state.ag.userLocation);
    const [isLoading, setIsLoading] = useState(false);

    const [searchData, setSearchData] = useState({
        searchText: searchText || '',
        searchType: searchType || '',
        searchTrigger: 0
    });

    const [shopData, setShopData] = useState({
        totalShops: 0,
        curShopCount: 0,
        shopList: [],
        offset: 0,
        limit: 12,
    });

    useEffect(() => {
        if(Boolean(userLocation?.location_attempted)){
            getTotalShops(searchData, userLocation);
        }
    }, [searchData, userLocation]);

    const getTotalShops = (searchData, userLocation) => {
        setIsLoading(true)
        apiPostJson(
            'ag-api/stores/',
            { identifier: 'get_total_store_count', search_text: searchData.searchText, search_type: searchData.searchType },
            (response) => {
                let totalShops = response?.total_store_count || 0;
                setShopData(prev => ({ ...prev, totalShops }));
                setTimeout(() => {
                    searchShops(searchData, userLocation)
                }, 100);
            },
            (error) => {
                setIsLoading(false)
                console.error("Error fetching total shops:", error);
            }
        );
    };

    const searchShops = (searchData, userLocation) => {
        setIsLoading(true);
        apiPostJson(
            'ag-api/stores/',
            { identifier: 'get_store_list', search_text: searchData.searchText, search_type: searchData.searchType, offset: shopData.offset, limit: shopData.limit, user_location: userLocation },
            (response) => {
                let newShops = response?.store_list || [];
                setShopData(prev => ({
                    ...prev,
                    shopList: [...prev.shopList, ...newShops],
                    curShopCount: prev.curShopCount + newShops.length,
                    offset: prev.offset + prev.limit,
                }));
                setIsLoading(false);
            },
            (error) => {
                setIsLoading(false);
                console.error("Error fetching shops:", error);
            }
        );
    };

    const loadMoreShops = () => {
        searchShops(searchData, userLocation);
    };

    const onSearch = (searchData) => {
        setShopData({
            totalShops: 0,
            curShopCount: 0,
            shopList: [],
            offset: 0,
            limit: 12,
        });
        setTimeout(() => {
            setSearchData(prev => ({ ...searchData, searchTrigger: prev.searchTrigger + 1 }));
        }, 50);
    };

    const openGoogleMaps = (lat, lng) => {
        const url = `https://www.google.com/maps?q=${lat},${lng}`;
        window.open(url, '_blank');
    };

    const formatTime = (duration) => {
        try {
            // Use regex to extract hours and minutes from the ISO 8601 duration
            const regex = /P(?:\d+D)?T(\d+H)?(\d+M)?/;
            const matches = duration.match(regex);

            // Extract hours and minutes from the matches (default to 0 if not present)
            const hours = matches[1] ? parseInt(matches[1], 10) : 0;
            const minutes = matches[2] ? parseInt(matches[2], 10) : 0;

            // Ensure the hours and minutes are always two digits
            const formattedHours = String(hours).padStart(2, '0');
            const formattedMinutes = String(minutes).padStart(2, '0');

            // Return the result in HH:MM format
            return `${formattedHours}:${formattedMinutes}`;
        }
        catch {
            return duration
        }
    }

    const sendIncrementStoreViewCountRequest = (store_id)=>{
        apiPostJson(
            'ag-api/stores/',
            { identifier:'store_view_count_increment', store_id: store_id },
            (response)=>{ console.log(response) },
            (response)=>{ console.log(response) },
        )
    }

    return (
        <div className="container mt-4">
            <SearchInput onSearch={onSearch} initSearchText={searchText} />
            {isLoading && <div className='mt-4'><LinearProgress /></div>}
            <Grid container spacing={4} sx={{ marginTop: '20px' }}>
                {shopData.shopList.map((shop) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={shop.store_id}>
                        <Card className='h-100 mt-4' sx={{ cursor: 'pointer' }}
                            onClick={() => { sendIncrementStoreViewCountRequest(shop.store_id); navigate(`/store-info/?storeID=${shop.store_id}`) }}
                        >
                            {/* Shop Image */}
                            <CardMedia
                                component="img"
                                height="200"
                                image={window.getMediaImageURL(shop.img) || ShopPlaceholderImage}
                                alt={shop.store_name}
                            />
                            <CardContent>
                                {/* Shop Name */}
                                <Typography variant="h6" component="div">
                                    {shop.store_name}
                                </Typography>
                                {/* Location Distance and Travel Time */}
                                {shop.location_distance?.text && <Typography variant="body2" color="textSecondary" component="p">
                                    <LocationIcon fontSize="small" /> Distance: {shop.location_distance?.text} 
                                </Typography>}
                                {shop?.travel_duration?.text && <Typography variant="body2" color="textSecondary" component="p">
                                    <HourglassEmpty fontSize="small" /> Travel Time: {shop?.travel_duration?.text}
                                </Typography>}
                                {/* Store Open and Close Time */}
                                <Typography variant="body2" color="textSecondary" component="p">
                                    <ScheduleIcon fontSize="small" /> Open: {formatTime(shop.store_open_time)} <br />
                                    <LockClockIcon fontSize="small" /> Close: {formatTime(shop.store_close_time)}
                                </Typography>
                                {/* Link to Google Maps */}
                                <Button
                                    size="small"
                                    color="primary"
                                    onClick={() => window.open(`https://www.google.com/maps?q=${shop.latitude},${shop.longitude}`, '_blank')}
                                    startIcon={<DirectionsIcon />}
                                >
                                    Directions
                                </Button>
                                {/* Call Shop if Phone Number is Present */}
                                {shop.phone1 && (
                                    <Button
                                        size="small"
                                        color="secondary"
                                        href={`tel:${shop.phone1}`}
                                        startIcon={<PhoneIcon />}
                                    >
                                        Call
                                    </Button>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            {/* No result found */}
            {(searchText || searchData.searchText) && shopData.totalShops === 0 && (
                <div className='text-center'>No Result Found</div>
            )}
            {/* Load more shops button */}
            {shopData.curShopCount < shopData.totalShops && !isLoading && (
                <div className="text-center mt-5">
                    <Button variant="outlined" color="primary" onClick={loadMoreShops}>
                        Load More
                    </Button>
                </div>
            )}

            {isLoading && (
                <div className='d-flex justify-content-center w-100'>
                    <CircularProgress />
                </div>
            )}
        </div>
    );
}
