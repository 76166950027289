import React, { useEffect, useState } from 'react';
import { Button, Card, CardMedia, CardContent, Typography, Grid, LinearProgress, CircularProgress, IconButton } from '@mui/material';
import ShopPlaceholderImage from '../../CommonComponents/ImagesComponent/StaticImages/ShopSamples/store_placeholder.png';
import { Schedule as ScheduleIcon, LockClock as LockClockIcon, Room as LocationIcon, Directions as DirectionsIcon, Phone as PhoneIcon, HourglassEmpty } from '@mui/icons-material';
import { apiPostJson } from '../../../Utilities/API';
import { useSelector } from 'react-redux';

export default function OwnersStores(props) {
    const { storeList } = props

 
    const formatTime = (duration) =>{
        // Use regex to extract hours and minutes from the ISO 8601 duration
        const regex = /P(?:\d+D)?T(\d+H)?(\d+M)?/;
        const matches = duration.match(regex);
      
        // Extract hours and minutes from the matches (default to 0 if not present)
        const hours = matches[1] ? parseInt(matches[1], 10) : 0;
        const minutes = matches[2] ? parseInt(matches[2], 10) : 0;
      
        // Ensure the hours and minutes are always two digits
        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
      
        // Return the result in HH:MM format
        return `${formattedHours}:${formattedMinutes}`;
    }
    
    return (
        <>
        <Typography variant="h4" component="div"  sx={{ marginTop: '20px', textAlign:'center' }}>
            My Stores
        </Typography>
        <Grid container spacing={4}>
            {Array.isArray(storeList) && storeList.map((shop) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={shop.store_id}>
                    <Card className='h-100 mt-4'>
                        {/* Shop Image */}
                        <CardMedia
                            component="img"
                            height="200"
                            image={window.getMediaImageURL(shop.img) || ShopPlaceholderImage}
                            alt={shop.store_name}
                        />
                        <CardContent>
                            {/* Shop Name */}
                            <Typography variant="h6" component="div">
                                {shop.store_name}
                            </Typography>
                            {shop.location_distance?.text && <Typography variant="body2" color="textSecondary" component="p">
                                <LocationIcon fontSize="small" /> Distance: {shop.location_distance?.text} km
                            </Typography>}
                            {shop?.travel_duration?.text && <Typography variant="body2" color="textSecondary" component="p">
                                <HourglassEmpty fontSize="small" /> Travel Time: {shop?.travel_duration?.text}
                            </Typography>}
                            {/* Store Open and Close Time */}
                            <Typography variant="body2" color="textSecondary" component="p">
                                <ScheduleIcon fontSize="small" /> Open: {formatTime(shop.store_open_time)} <br />
                                <LockClockIcon fontSize="small" /> Close: {formatTime(shop.store_close_time)}
                            </Typography>
                            
                            <Typography variant="body1" color="textSecondary" component="p">
                                View Count: {shop.view_count}   
                            </Typography>
                            <Button
                                size="small"
                                color="primary"
                                onClick={() => window.open(`https://www.google.com/maps?q=${shop.latitude},${shop.longitude}`, '_blank')}
                                startIcon={<DirectionsIcon />}
                            >
                                Directions
                            </Button>
                            {/* Call Shop if Phone Number is Present */}
                            {shop.phone1 && (
                                <Button
                                    size="small"
                                    color="secondary"
                                    href={`tel:${shop.phone1}`}
                                    startIcon={<PhoneIcon />}
                                >
                                    Call
                                </Button>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
        </>
    );
}
