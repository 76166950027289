import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AGManagerCorner from './AGManagerCorner';

export default function AGManager(props) {
    const navigate = useNavigate()
    // const isLoggedIn = true
    const user = useSelector(state => state.user)
    const isLoggedIn = user.isLoggedIn && user.userData.user_type === 'admin'

    useEffect(() => { 
        if (!isLoggedIn) {
            navigate('/ag-manager-login')
        }
    }, [])

    return isLoggedIn && <AGManagerCorner {...props} />
}