import React from 'react';
import OfferEnable from './OfferEnable';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserData, updateUserLoginStatus } from '../../store/userSlice';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';

export default function AGManagerCorner(props) {
  const user = useSelector(state=>state.user)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const logout = () => {
    // setStep('success');
    localStorage.removeItem('jwtToken');
    dispatch(updateUserData({ userData: { mobile: null } }))
    dispatch(updateUserLoginStatus({ isLoggedIn: false }))
    navigate('/ag-manager-login/')
  }

  return (
    <div>
      <div className='d-flex'>
        {user?.isLoggedIn && user?.userData?.mobile && (
          <><Typography variant="h6" display='inline' sx={{ ml: 3, mr: 3 }}>
            Logged in with: {user?.userData.mobile}
          </Typography>
            <Button variant='outlined' onClick={logout} >Logout</Button>
          </>
        )}
      </div>
      <OfferEnable />
    </div>
  );
}
