import React, {lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Homepage from "./Homepage";
import TermsAndConditions from "./SimplePages/Terms";
import PrivacyPolicy from "./SimplePages/PrivacyPolicy";
import FAQ from "./SimplePages/FAQ";
import ContactUs from "./SimplePages/ContactUs";
import AboutUs from "./SimplePages/AboutUs";
import Search from "./ShopList";
import StoreManager from "./StoreManager";
import ParticularShopDisplay from "./ShopList/ParticularShopDisplay";
import Offers from "./OFFERS";
import StoreOwnersCorner from "./StoreOwner";
import StoreOwnerLogin from "./StoreOwner/OwnerLogin";
import AGManager from "./AG_MANAGER";
import ManagerLogin from "./AG_MANAGER/ManagerLogin";
// import Courses from "./components/PrimaryComponents/Courses";
// import Projects from "./components/PrimaryComponents/Projects";
// import Discussions from "./components/PrimaryComponents/Discussions";
// import Blogs from "./components/PrimaryComponents/Blogs";
// import Interview from "./components/PrimaryComponents/Interview";
// import LoginScreen from "./components/SecondaryComponents/Auth/LoginScreen";
// import UserProfilePage from "./components/PrimaryComponents/UserProfile";
// import AdminPages from "./components/PrimaryComponents/AdminPages";
// import CreatorsCorner from "./components/PrimaryComponents/CreatorsCorner";

export default function AppRoutes() {

  // const Homepage = lazy(() => import('./components/PrimaryComponents/Homepage'));
//   const Courses = lazy(() => import('./components/PrimaryComponents/Courses'));

  const FallbackComponent = <div className="d-flex justify-content-center m-5 p-5">Loading...</div>

  return (
    <Routes>
      <Route path="" element={ <Homepage/> } />
      <Route path="search" element={ <Search /> } />
      <Route path="store-info/*" element={ <ParticularShopDisplay /> } />
      <Route path="store-owners-corner/*" element={ <StoreOwnersCorner /> } />
      <Route path="store-owner-login/*" element={ <StoreOwnerLogin /> } />
      <Route path="offers/*" element={ <Offers/> } />
      <Route path="ag-admin/*" element={ <AGManager />} />
      <Route path="ag-manager-login/*" element={ <ManagerLogin />} />
      <Route path="terms-conditions" element={ <TermsAndConditions /> } />
      <Route path="privacy-policy" element={ <PrivacyPolicy /> } />
      <Route path="faq" element={ <FAQ/> } />
      <Route path="contact" element={ <ContactUs/> } />
      <Route path="about" element={ <AboutUs/> } />
      {/* <Route path="courses/*" element={<Suspense fallback={FallbackComponent} ><Courses /> </Suspense>} /> */}
    </Routes>
  );
}
