import React from 'react';
import AppIcon from './StaticImages/AG_Icon.jpeg'
import GooglePlayBadge from './StaticImages/PlayStoreBadge.png'
export default function index() {
  return (
    <div>
      
    </div>
  );
}


// export const AppLogoImage = ()=><img style={{ height:'30px', width:'30px'}} src='https://storage.googleapis.com/edstreak/static/images/App-logo-transparent.e4a48fc00ac4276429dc.png' alt='app_logo'/>
// export const AppLogoImage = ({height='30px', width='30px'})=><img style={{ height:height, width:width}} src='https://storage.googleapis.com/edstreak/static/images/AppLogoCSmall.jpg' alt='app_logo'/>

export const AppLogoImage = ({height='30px', width='30px'})=><img style={{ height:height, width:width}} src={AppIcon} alt='app_logo'/>

export const AppDownloadImage = ({height='50px', width='160px'})=><img style={{ height:height, width:width}} src={GooglePlayBadge} alt='app'/>