import {createSlice} from '@reduxjs/toolkit'


export const userSlice = createSlice({
    name:'user',
    initialState:{
        // isLoggedIn:true,
        // userData: {
        //     mobile: '6666666666',
        //     userID: 3
        // }
        isLoggedIn:false,
        userData: {
            mobile: '',
            user_id: '',
            user_type: '',
            email:'',
        }
    },
    reducers:{
        updateUserLoginStatus:(state, action)=>{
            state.isLoggedIn = Boolean(action.payload.isLoggedIn)
        },
        updateUserData:(state, action)=>{
            state.userData = {...state.userData, ...action.payload.userData }
        }
    }
})

export const {
    updateUserLoginStatus,
    updateUserData
} = userSlice.actions

export default userSlice.reducer