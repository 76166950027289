import React, { useEffect, useState } from 'react';
import { TextField, Button, Typography, Box, Alert } from '@mui/material';
import { apiPostJson } from '../../../Utilities/API';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserData, updateUserLoginStatus } from '../../../store/userSlice';
import { useNavigate } from 'react-router-dom';

const StoreOwnerLogin = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const isLoggedIn = useSelector(state => state.user.isLoggedIn)
    const [step, setStep] = useState('generate'); // 'generate' or 'verify'
    const [mobile, setMobile] = useState('');
    const [otp, setOtp] = useState('');
    const [deviceToken, setDeviceToken] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState(null);
    // Validate if the mobile number is a 10-digit number
    const validateMobileNumber = (mobile) => {
        const mobileRegex = /^[0-9]{10}$/;
        return mobileRegex.test(mobile);
    };

    const handleGenerateOtp = async () => {
        setError(null); 
        if (!validateMobileNumber(mobile)) {
            setError('Please enter a valid 10-digit mobile number.');
            return;
        }
        apiPostJson(
            'ag-api/store-owner-login/',
            { identifier: 'generate_otp', mobile },
            (response) => {
                setDeviceToken(response?.device_token);
                setStep('verify');
                setMessage('OTP has been sent to your mobile.');
            },
            (response) => {
                setError('Failed to generate OTP. Please try again.');
            }
        );
    };

    const handleVerifyOtp = async () => {
        setError(null); // Clear any existing error
        apiPostJson(
            'ag-api/store-owner-login/',
            { identifier: 'verify_otp', mobile, otp, device_token: deviceToken },
            (response) => {
                // setMessage('OTP verified successfully.');
                // setStep('success');
                localStorage.setItem('jwtToken', response.token); // Store JWT token
                dispatch(updateUserData({userData:{mobile: mobile}}))
                dispatch(updateUserLoginStatus({isLoggedIn:true}))
                navigate('/store-owners-corner/')
            },
            (response) => {
                console.log('response error1', response, response?.response)
                if(response.message){
                    setError(response.message);
                }
                else{
                    setError('Failed to verify OTP. Please try again.');
                }
            }
        );
    };

    useEffect(()=>{
        if(isLoggedIn){
            navigate('/store-owners-corner')
        }
    },[isLoggedIn])

    return (
        <Box sx={{ maxWidth: 400, margin: 'auto', mt: 8 }}>
            <Typography variant="h4" gutterBottom align="center">
                {step === 'generate' ? 'Store Owner Login' : 'Verify OTP'}
            </Typography>

            {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

            {message && <Alert severity="success" sx={{ mb: 2 }}>{message}</Alert>}

            {step === 'generate' && (
                <>
                    <TextField
                        label="Mobile Number"
                        variant="outlined"
                        fullWidth
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                        sx={{ mb: 2 }}
                    />
                    <Button variant="contained" fullWidth onClick={handleGenerateOtp}>
                        Generate OTP
                    </Button>
                </>
            )}

            {step === 'verify' && (
                <>
                    <TextField
                        label="Enter OTP"
                        variant="outlined"
                        fullWidth
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        sx={{ mb: 2 }}
                    />
                    <Button variant="contained" fullWidth onClick={handleVerifyOtp}>
                        Verify OTP
                    </Button>
                </>
            )}

            {step === 'success' && (
                <Typography variant="h6" align="center" color="success.main">
                    OTP Verified. You are logged in!
                </Typography>
            )}
        </Box>
    );
};

export default StoreOwnerLogin;
