import React from 'react';
import { Container, Typography, Button, Grid, Box, TextField, Card, CardContent, Link } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import StoreIcon from '@mui/icons-material/Store';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import PharmaBannerImage from '../CommonComponents/ImagesComponent/StaticImages/ShopSamples/pharma.jpg'
import BeautyBannerImage from '../CommonComponents/ImagesComponent/StaticImages/ShopSamples/beauty.jpg'
import GroceryBannerImage from '../CommonComponents/ImagesComponent/StaticImages/ShopSamples/grocery.jpg'
import SalonBannerImage from '../CommonComponents/ImagesComponent/StaticImages/ShopSamples/salon.jpg'
import MarketBannerImage from '../CommonComponents/ImagesComponent/StaticImages/ShopSamples/market.jpg'

import './Homepage.css'
import ShopCategory from './Category';
import StoreSearchInput from '../ShopList/SearchInput';
import { useNavigate } from 'react-router-dom';

const Homepage = () => {
    const navigate = useNavigate()
    const deskTopHomeComponent = <div className="homepage-container border border-5 mt-5 " style={{ borderColor: 'teal', borderRadius: '30px' }}>
        <Box className="image-top-left">
            <img src={MarketBannerImage} alt="Top Left" />
        </Box>
        <Box className="image-top-right">
            <img src={GroceryBannerImage} alt="Top Right" />
        </Box>
        <Box className="image-bottom-left">
            <img src={PharmaBannerImage} alt="Bottom Left" />
        </Box>
        <Box className="image-bottom-right">
            <img src={SalonBannerImage} alt="Bottom Right" />
        </Box>
        <Container className="center-text" maxWidth="md">
            <Typography variant="h3" align="center" className="center-heading">
                Connecting Local Businesses with Customers
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <StoreSearchInput onSearch={(searchData)=>{navigate(`/search/?searchText=${searchData?.searchText}&searchType=${searchData?.searchType}`)}}  searchBorderColor={'white'}/>
            </Box>
        </Container>
    </div>

    const tabletHomeComponent =  <div className="homepage-container border border-5 mt-5 " style={{ borderColor: 'teal', borderRadius: '30px' }}>
        <Box className="image-top-left">
            <img src={MarketBannerImage} alt="Top Left" />
        </Box>
        <Box className="image-top-right">
            <img src={GroceryBannerImage} alt="Top Right" />
        </Box>
        <Box className="image-bottom-left">
            <img src={PharmaBannerImage} alt="Bottom Left" />
        </Box>
        <Box className="image-bottom-right">
            <img src={SalonBannerImage} alt="Bottom Right" />
        </Box>
        <Container className="center-text" maxWidth="md" sx={{backgroundColor:'teal', opacity:0.7, padding:'30px', borderRadius:'30px'}}>
            <Typography variant="h3" align="center" className="center-heading">
                Connecting Local Businesses with Customers
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <TextField
                    label="Search for shops, offers, and more..."
                    variant="outlined"
                    className="search-bar"
                />
                <Button variant="contained" color="primary" className="search-button">
                    <SearchIcon />
                </Button>
            </Box>
        </Container>
    </div>


    const mobileHomeComponent = <div>
        <Container className="center-text" maxWidth="md">
            <Typography variant="h3" align="center" className="center-heading" color={'teal'}>
                Connecting Local Businesses with Customers
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <TextField
                    label="Search for shops, offers, and more..."
                    variant="outlined"
                    className="search-bar"
                />
                <Button variant="contained" color="primary" className="search-button">
                    <SearchIcon />
                </Button>
            </Box>
        </Container>
    </div>

    return (
        <div>
            {
                window.isDeviceMobile ?
                    mobileHomeComponent
                    : window.isDeviceTablet ?
                        tabletHomeComponent
                        : deskTopHomeComponent
            }
            <Box sx={{ py: 6 }}>
                <Container>
                    <Typography variant="h4" align="center" gutterBottom>
                        Why Choose ApniGully?
                    </Typography>
                    <Grid container spacing={4} sx={{ mt: 3 }}>
                        <Grid item xs={12} sm={6} md={4}>
                            <Card>
                                <CardContent sx={{ textAlign: 'center' }}>
                                    <StoreIcon fontSize="large" color="primary" />
                                    <Typography variant="h6" gutterBottom>
                                        Discover Local Shops
                                    </Typography>
                                    <Typography>
                                        Find nearby shops with essential details, offers, and services in your area.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Card>
                                <CardContent sx={{ textAlign: 'center' }}>
                                    <LocalOfferIcon fontSize="large" color="primary" />
                                    <Typography variant="h6" gutterBottom>
                                        Exclusive Offers
                                    </Typography>
                                    <Typography>
                                        Get notified about exclusive discounts and offers from local businesses.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Card>
                                <CardContent sx={{ textAlign: 'center' }}>
                                    <LocationOnIcon fontSize="large" color="primary" />
                                    <Typography variant="h6" gutterBottom>
                                        Easy Navigation
                                    </Typography>
                                    <Typography>
                                        Navigate to the best shops with integrated map support for easy directions.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <ShopCategory />
            {/* Footer Section */}
            <Box sx={{ py: 4, backgroundColor: '#3f51b5', color: 'white' }}>
                <Container>
                    <Typography variant="h6" gutterBottom align="center">
                        ApniGully - Connecting Local Businesses with Customers
                    </Typography>
                    <Typography align="center">
                        <Link href="https://play.google.com/store/apps/details?id=com.apnigully.app&pcampaignid=web_share" color="inherit" underline="hover">
                            Download our app on Google Play
                        </Link>
                    </Typography>
                </Container>
            </Box>
        </div>
    );
};

export default Homepage;
