export const initialGlobalConfAndFuncSetup = () => {
    window.production = process.env.NODE_ENV === "production";
    window.config = {
        API_URL: process.env.NODE_ENV === "production" ? process.env.REACT_APP_PROD_API_BASE_URL : process.env.REACT_APP_DEV_API_BASE_URL,
        MEDIA_BASE_URL: process.env.NODE_ENV === "production" ? process.env.REACT_APP_PROD_STATIC_DATA_URL : process.env.REACT_APP_DEV_STATIC_DATA_URL,
    };
    window.getMediaImageURL = getMediaImageURL
    window.role = "user";
    window.log = (...params)=>{
        if(process.env.NODE_ENV === 'development'){
            console.log(...params)
        }
    }
};

const getMediaImageURL = (imageURL) => {
    if (imageURL) {
        const regex = /[./]*uploads\/(.*)/;
        const match = imageURL.match(regex);
        if (match) {
            let url = new URL(match[1], window.config.MEDIA_BASE_URL)
            return url.href
        }
        if (imageURL.startsWith('./uploads')) {
            return window.config.MEDIA_BASE_URL + imageURL.substring(10);
        } else if (imageURL.startsWith('/uploads')) {
            return window.config.MEDIA_BASE_URL + imageURL.substring(9);;
        } else if (imageURL.startsWith('uploads')) {
            return window.config.MEDIA_BASE_URL + imageURL.substring(8);;
        }
        else {
            return window.config.MEDIA_BASE_URL + '/../' + imageURL;
        }
    }
    return '';
}

// function getURL(url1, url2) {
//     function isValidUrl(url) {
//         try {
//             new URL(url);
//             return true;
//         } catch (e) {
//             return false;
//         }
//     }
//     if (!url2) {
//         return isValidUrl(url1);
//     }
//     url1 = url1.replace(/\/+$/, '');
//     url2 = url2.replace(/^\/+/, '');
//     return url1 + '/' + url2;
// }