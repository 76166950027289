export function setCookie(cname, cvalue, exdays) {
    try {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;Secure;HttpOnly";
        return true; // Return true on success
    } catch (error) {
        console.error('Error setting cookie:', error);
        return false; // Return false on error
    }
}

export function getCookie(cname) {
    try {
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return ""; // Return empty string if cookie not found
    } catch (error) {
        console.error('Error getting cookie:', error);
        return ""; // Return empty string on error
    }
}

export function deleteCookie(cname) {
    try {
        document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; Secure; HttpOnly";
        return true; // Return true on success
    } catch (error) {
        console.error('Error deleting cookie:', error);
        return false; // Return false on error
    }
}

export function checkCookie() {
    let user = getCookie("username");
    if (user != "") {
        console.log("Welcome again " + user);
    } else {
        user = prompt("Please enter your name:", "");
        if (user != "" && user != null) {
            setCookie("username", user, 365);
        }
    }
}











// export function setCookie(cname, cvalue, exdays) {
//     const d = new Date();
//     d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
//     let expires = "expires=" + d.toUTCString();
//     document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
// }

// export function getCookie(cname) {
//     let name = cname + "=";
//     let ca = document.cookie.split(';');
//     for (let i = 0; i < ca.length; i++) {
//         let c = ca[i];
//         while (c.charAt(0) == ' ') {
//             c = c.substring(1);
//         }
//         if (c.indexOf(name) == 0) {
//             return c.substring(name.length, c.length);
//         }
//     }
//     return "";
// }

// export function checkCookie() {
//     let user = getCookie("username");
//     if (user != "") {
//         console.log("Welcome again " + user);
//     } else {
//         user = prompt("Please enter your name:", "");
//         if (user != "" && user != null) {
//             setCookie("username", user, 365);
//         }
//     }
// }