import ApniGullyApp from './Apnigully';
import './App.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import { useSetupUI } from './Utilities/InitSetup/UISetup';
import { initialGlobalConfAndFuncSetup } from './Utilities/InitSetup/GlobalConfigSetup';

function App() {
  const success = useSetupUI()
  initialGlobalConfAndFuncSetup()

  return (
    <div className="App">
      <ApniGullyApp />
    </div>
  );
}

export default App;