import React, { useEffect, useState } from 'react';
import { Container, Typography, Grid, Box, Card, CardContent, CardMedia, Button } from '@mui/material';
import { apiPostJson } from '../../Utilities/API';
import { useNavigate } from 'react-router-dom';

export default function ShopCategory(props) {
    const navigate = useNavigate()
    const [categoryInfo, setCategoryInfo] = useState({
        total_categories: 0,
        category_list: [],
    });
    const [visibleCategories, setVisibleCategories] = useState(8); // Initial number of categories to display

    const fetchAllCategoriesList = () => {
        apiPostJson(
            'ag-api/stores/',
            { identifier: 'get_category_list' },
            (response) => {
                let catData = {};
                catData['total_categories'] = response?.total_categories || 0;
                catData['category_list'] = response?.category_list || [];
                setCategoryInfo(catData);
            },
            () => { }
        );
    };

    useEffect(() => {
        fetchAllCategoriesList();
    }, []);


    const handleLoadMore = () => {
        setVisibleCategories(categoryInfo.category_list.length); // Show all categories when the button is clicked
    };

    return (
        <Box sx={{ py: 6, backgroundColor: '#f5f5f5' }}>
            <Container>
                <Typography variant="h4" align="center" gutterBottom>
                    Popular Shop Categories
                </Typography>
                <Grid container spacing={4} sx={{ mt: 3 }}>
                    {categoryInfo.category_list.slice(0, visibleCategories).map((category) => (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={3}
                            key={category.category_id}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center', // Center content for smaller screens
                            }}
                        >
                            <Card
                                sx={{
                                    width: { xs: '100%', sm: '90%', md: '100%' }, // Adjust card width based on screen size
                                    maxWidth: '300px', // Set a max width for larger screens
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center', // Align center horizontally
                                    cursor:'pointer'
                                }}
                                onClick={() => { navigate(`/search/?searchText=${category.category_name}&searchType=category`) }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center', // Align the image in the center of the box
                                        height: 140, // Set the height for consistent card media area
                                    }}
                                >
                                    <CardMedia
                                        component="img"
                                        image={window.getMediaImageURL(category.category_image)}
                                        alt={category.category_name}
                                        sx={{
                                            objectFit: 'cover',
                                            height: 100,
                                            width: 100, // Make the image square
                                        }}
                                    />
                                </Box>
                                <CardContent>
                                    <Typography
                                        variant="h6"
                                        align="center"
                                        sx={{ fontSize: { xs: '1rem', md: '1.25rem', wordBreak:'break-word' } }} // Responsive font size for card title
                                    >
                                        {category.category_name}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

                {/* Load More Button */}
                {visibleCategories < categoryInfo.category_list.length && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                        <Button variant="contained" onClick={handleLoadMore}>
                            Load All Categories
                        </Button>
                    </Box>
                )}
            </Container>
        </Box>
    );
}
