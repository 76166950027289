import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { apiPostJson } from '../../Utilities/API';
import { Box, Typography, Card, CardContent, Grid, Avatar, Divider, Paper, CardMedia, Button } from '@mui/material';
import { Directions, Phone } from '@mui/icons-material';

export default function ParticularShopDisplay(props) {

    const [searchParams] = useSearchParams()
    const storeID = searchParams.get('storeID');
    const [storeData, setStoreData] = useState({})
    const fetchStoreDetails = () => {
        apiPostJson(
            'ag-api/stores/',
            { identifier: 'get_store_details', storeID: storeID },
            (response) => { if (response.store_details) { setStoreData(response.store_details) } },
            (response) => { }
        )
    }

    useEffect(() => {
        fetchStoreDetails()
    }, [])

    const formatTime = (duration) =>{
        try{
        // Use regex to extract hours and minutes from the ISO 8601 duration
        const regex = /P(?:\d+D)?T(\d+H)?(\d+M)?/;
        const matches = duration.match(regex);
      
        // Extract hours and minutes from the matches (default to 0 if not present)
        const hours = matches[1] ? parseInt(matches[1], 10) : 0;
        const minutes = matches[2] ? parseInt(matches[2], 10) : 0;
      
        // Ensure the hours and minutes are always two digits
        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
      
        // Return the result in HH:MM format
        return `${formattedHours}:${formattedMinutes}`;
        }
        catch{
            return duration
        }
    }
    const store = storeData
    return (
        <Box sx={{ padding: '20px' }}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} sm={8}>
              <Card sx={{ borderRadius: '15px', boxShadow: 4 }}>
                {/* Store Image as a separate image */}
                <CardMedia
                  component="img"
                //   height="350"
                  width="500"
                  image={window.getMediaImageURL(store.img)}
                  alt={store.store_name}
                  sx={{ borderRadius: '15px 15px 0 0' }}
                />
                <CardContent>
                  {/* Store Name */}
                  <Typography
                    variant="h4"
                    component="div"
                    sx={{ fontWeight: 'bold', color: 'teal', textAlign: 'center', marginBottom: 2 }}
                  >
                    {store.store_name}
                  </Typography>
                  {/* Store Description */}
                  <Typography variant="body1" sx={{ textAlign: 'center', marginBottom: 3 }}>
                    {store.description}
                  </Typography>
    
                  <Divider />
    
                  {/* Address and Contact Information */}
                  <Box sx={{ marginY: 3 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Paper elevation={2} sx={{ padding: 2 }}>
                          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                            Address
                          </Typography>
                          <Typography variant="body1" color="text.secondary">
                            {store.address}
                          </Typography>
                          <Button
                                    size="small"
                                    color="primary"
                                    onClick={() => window.open(`https://www.google.com/maps?q=${store.latitude},${store.longitude}`, '_blank')}
                                    startIcon={<Directions />}
                                >
                                    Directions
                                </Button>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper elevation={2} sx={{ padding: 2, height:'100%' }}>
                          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                            Contact
                          </Typography>
                          {store.phone1 && (
                                    <Button
                                        size="small"
                                        color="secondary"
                                        href={`tel:${store.phone1}`}
                                        startIcon={<Phone />}
                                    >
                                        {store.phone1}
                                    </Button>
                            )}
                            {store.phone2 && (
                                    <Button
                                        size="small"
                                        color="secondary"
                                        href={`tel:${store.phone2}`}
                                        startIcon={<Phone />}
                                    >
                                        {store.phone2}
                                    </Button>
                            )}
                          {!(store.phone1 || store.phone2) && <Typography variant="body1" color="text.secondary">
                           { "No contact information available."}
                          </Typography>}
                        </Paper>
                      </Grid>
                    </Grid>
                  </Box>
    
                  <Divider />
    
                  {/* Store Timings */}
                  <Box sx={{ marginTop: 3 }}>
                    <Typography variant="h5" sx={{ textAlign:'center', fontWeight: 'bold', color: 'teal', marginBottom: 2 }}>
                      Store Timings
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Paper elevation={2} sx={{ padding: 2, textAlign: 'center' }}>
                          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                            Open Time
                          </Typography>
                          <Typography variant="body1">{formatTime(store.store_open_time)}</Typography>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper elevation={2} sx={{ padding: 2, textAlign: 'center' }}>
                          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                            Close Time
                          </Typography>
                          <Typography variant="body1">{formatTime(store.store_close_time)}</Typography>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      );
    
}
