import React from 'react';
import { Container, Typography, List, ListItem, Divider, Box } from '@mui/material';

const TermsAndConditions = () => {
  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom align="center">
        Terms and Conditions
      </Typography>

      <Typography variant="h6" gutterBottom>
        ApniGully terms of use:
      </Typography>

      <Typography paragraph>
        This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable
        and amended provisions pertaining to electronic record. This document is published in accordance with the provisions of rule
        3(1) of the Information Technology (Intermediaries guidelines) rules, 2011 that requires publishing the rules and regulations
        privacy policy and terms of use for access or usage of ApniGully application for mobile. When you access the platform you
        agree to be bound by these terms and conditions.
      </Typography>

      <Divider />
      <Typography variant="h6" gutterBottom>
        Changes:
      </Typography>

      <Typography paragraph>
        We may periodically change the Terms and the Site without notice, and you are responsible for checking these Terms
        periodically for revisions. All amended Terms become effective upon our posting to the Site, and any use of the site after
        such revisions have been posted signifies your consent to the changes.
      </Typography>

      <Divider />
      <Typography variant="h6" gutterBottom>
        Terms and Conditions:
      </Typography>

      <List sx={{ listStyleType: 'disc', pl: 4 }}>
        <ListItem sx={{ display: 'list-item' }}>
          We use a diverse range of information, text, photographs, designs, graphics, images, sound and video recordings, animation, content, advertisement, and other materials and effects (collectively "materials") for the search services on the platform. We provide the Material through the platform FOR YOUR PERSONAL AND NON-COMMERCIAL USE ONLY.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          While every attempt has been made to ascertain the authenticity of the platform content, ApniGully is not liable for any kind of damages, losses, or actions arising directly or indirectly due to access and/or use of the content on the platform, including but not limited to decisions based on the content in the platform which results in any loss of data, revenue, profits, property, infection by viruses, etc.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          All information displayed on the ApniGully App, related to a shop, is made available to users on a best-effort basis, and ApniGully cannot guarantee the correctness of such information.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          ApniGully app may ask for access to your mobile location to display the distance of the shop. Without appropriate location permission, the location of a shop cannot be displayed.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          Information displayed on the app is either taken from the public domain or provided by shop owners. ApniGully does not display any confidential information about shops or their owners.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          Only Registered shops of ApniGully will be displayed on the ApniGully mobile application.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          ApniGully is the medium to bring Shops and Customers together, and we are not responsible for any commitments made by Shops/Business establishments regarding discounts or any special offers.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          There are no financial transactions allowed through this App. All payments between customers and stores will happen at the store's billing section, and ApniGully will not be responsible for any correctness in the Billing.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          The customer has to ascertain the billing total for its correctness before making any payment to the Shop/Business establishment, either by Cash, Card, or Cheque.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          ApniGully may tie up with Stores, Brands, or Online Advertisers, and there shall be no objection.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          Minor Children are required to seek permission from their Parents before engaging in any financial transactions with the Shops.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          All disputes, if any, regarding pricing or the quality of products, must be resolved by the customer and the store, and ApniGully holds no responsibility toward the same.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          Any false representation of our App or copying of the App design, logo, without our authorization will be considered a crime, and ApniGully will hold such persons, companies, or organizations responsible, and legal action will be taken in accordance with Indian Law.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          You shall not make any negative or derogatory statements or comments about the ApniGully App.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          ApniGully will not be responsible for any damage caused to your mobile or handheld device in any form.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          ApniGully App is not conducting any financial transactions, so we are not required to collect your KYC documents, nor are we saving any such data in written or electronic form.
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          You shall not engage in purchasing any products or services which are not allowed by Law. All responsibility lies with the customer and shop for their products and services.
        </ListItem>
      </List>

      <Divider />
      <Typography variant="h6" gutterBottom>
        Content Disclaimer:
      </Typography>

      <Typography paragraph>
        ApniGully communicates information provided and created by advertisers, content partners, software developers, publishers,
        marketing agents, employees, users, resellers, and other third parties. While every attempt has been made to ascertain the
        authenticity of the content on the platform, ApniGully has no control over content, the accuracy of such content, integrity
        or quality of such content, and the information on our pages and material on the platform may include technical inaccuracies
        or typographical errors. We make no guarantees, nor can we be responsible for any such information, including its authenticity,
        currency, content, quality, copyright compliance or legality, or any resulting loss or damage.
      </Typography>

      <Box mb={4} />
    </Container>
  );
};

export default TermsAndConditions;
