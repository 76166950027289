import {configureStore} from '@reduxjs/toolkit'
// import userReducer from './userSlice'
import uiSlice from './uiSlice'
import agSlice  from './agSlice'
import userSlice  from './userSlice'

export default configureStore({
    reducer:{
        'ag': agSlice,
        'ui': uiSlice,
        'user': userSlice,
    }
})